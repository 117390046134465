<!--
 * @Description: 底部停车-首页 carParking
 * @Author: hj
 * @Github: 无
 * @Date: 2021-04-01 00:32:43
 * @LastEditors: yanxiao
-->
<template>
  <div class='body'>
    <div class="map-container"
         id="map">
    </div>
    <div class="search">
      <van-search v-model="topParkName"
                  @search="searchParkName"
                  placeholder="请输入您想到达的停车场" />
    </div>
    <div class="middle">
      <div class="middle-info">
        <!-- 停车场 -->
        <div v-if="showParkType == 1">
          <van-row class="icon-title">
            <van-image :src="require('@/assets/homePage/searchicon.png')"
                       class="icon" />
            <span>
              优选
              <span class="title-info">3</span>
              个
              <span class="title-info">目的地附近</span>
              的停车场。
            </span>
          </van-row>
          <van-list>
            <van-cell v-for="(item, index) in parkList"
                      :key="index"
                      @click="handleClickParkItem(item)">
              <van-row class="main-info">
                <van-col :span="21"
                         class="main-info-list">
                  <van-row class="main-list"
                           style="color:black ;font-size:17px">{{item.parkName}}</van-row>
                  <van-row class="main-list">
                    <van-col :span="11">
                      剩余车位:<span class="list-info">{{item.emptySpace}}</span>个
                    </van-col>
                    <van-col :span="13">
                      距离:<span class="list-info">{{item.distance}}</span>
                    </van-col>
                  </van-row>
                  <van-row class="main-list"
                           style="color:#606266 ;font-size:14px">
                    <van-icon name="location-o" />{{item.address}}
                  </van-row>

                </van-col>
                <van-col :span="3"
                         class="right-info">
                  <van-row>
                    <van-image class="temp-icon"
                               @click="handleClickNavigate(item)"
                               :src="require('@/assets/homePage/daohang.png')" />
                  </van-row>
                  <van-row class="temp-daohan"
                           @click="handleClickNavigate(item)">导航</van-row>
                </van-col>
              </van-row>
            </van-cell>
          </van-list>
        </div>
        <!-- 停车路段 -->
        <div v-if="showParkType == 2">
          <van-row class="icon-title">
            <van-image :src="require('@/assets/homePage/searchicon.png')"
                       class="icon" />
            <span>
              优选
              <span class="title-info">
                3
              </span>
              个
              <span class="title-info">
                目的地附近
              </span>
              的停车路段。
            </span>
          </van-row>
          <van-list style="heigh:300px;">
            <van-cell v-for="(item, index) in onroadPark"
                      :key="index"
                      @click="handleClickParkRoadItem(item)">
              <van-row class="main-info">
                <van-col :span="21"
                         class="main-info-list">
                  <van-row class="main-list"
                           style="color:black ;font-size:17px">{{item.parkName}}</van-row>
                  <van-row class="main-list">
                    <van-col :span="11">
                      剩余车位:<span class="list-info">{{item.emptySpace}}</span>个
                    </van-col>
                    <van-col :span="13">
                      距离:<span class="list-info">{{item.distance}}</span>
                    </van-col>
                  </van-row>
                  <van-row class="main-list"
                           style="color:#606266 ;font-size:14px">
                    <van-icon name="location-o" />{{item.address}}
                  </van-row>
                </van-col>
                <van-col :span="3"
                         class="right-info">
                  <van-row>
                    <van-image class="temp-icon"
                               @click="handleClickNavigate(item)"
                               :src="require('@/assets/homePage/daohang.png')" />
                  </van-row>
                  <van-row class="temp-daohan"
                           @click.native="handleClickNavigate(item)">导航</van-row>
                </van-col>
              </van-row>
            </van-cell>
          </van-list>
        </div>
      </div>
      <!-- 切换 -->
      <van-row class="middle-bar">
        <div @click="handleClickChange(1)"
             class="tab-item">
          <span :class="showParkType==1? 'select-tab-active':'select-tab'">附近停车场</span>
        </div>
        <div @click="handleClickChange(2)"
             class="tab-item">
          <span :class="showParkType==2? 'select-tab-active':'select-tab'">附近停车路段</span>
        </div>
      </van-row>
    </div>
    <!--点击停车场显示弹出窗 -->
    <van-popup v-model="parkPopupShow"
               position="bottom"
               closeable
               :style="{ height: '60%' }">
      <van-row v-model="parkPopList"
               class="parkPop">
        <van-row class="popParkName">
          {{parkPopList.parkName}}
        </van-row>
        <van-row class="lineTwo">
          <van-col :span=12
                   class="lineTwoCol">
            <van-row class="emptyText">空位</van-row>
            <van-row class="emptyNumber">{{parkPopList.emptySpace}}<span class="emptyNumberSpan">个</span></van-row>
          </van-col>
          <van-col :span=12
                   class="lineTwoCol">
            <van-row class="distanceText">距离</van-row>
            <van-row class="distanceNumber">{{parkPopList.distance}} </van-row>
            <!-- <span class="distanceNumberSpan">千米</span> -->
          </van-col>
        </van-row>
        <van-row class="lineThree">
          <span class="lineTreeText">地址:</span><span class="lineTreeContent">{{parkPopList.address}}</span>
        </van-row>
        <van-row class="textBill">
          收费规则
        </van-row>
        <van-row class="lineFour">
          1.白天({{parkPopBillList.dayBeginTime}}-{{parkPopBillList.dayEndTime}})
        </van-row>
        <van-row class="lineFive">
          <van-col :span=6
                   class="lineFiveCol">计费金额</van-col>
          <van-col :span=6
                   class="lineFiveCol">免费时长</van-col>
          <van-col :span=6
                   class="lineFiveCol">重新计费时长</van-col>
          <van-col :span=6
                   class="lineFiveCol">封顶金额</van-col>
        </van-row>
        <van-row class="lineFive">
          <van-col :span=6
                   class="lineFiveCol">{{parkPopBillList.dayBillingPrice}}元</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{parkPopBillList.dayFreeDuration}}分钟</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{parkPopBillList.dayMinBillingDuration}}分钟</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{parkPopBillList.dayCeilingAmount}}元</van-col>
        </van-row>
        <van-row class="lineFour">
          2.夜间({{parkPopBillList.nightBeginTime}}-{{parkPopBillList.nightEndTime}})
        </van-row>
        <van-row class="lineFive">
          <van-col :span=6
                   class="lineFiveCol">计费金额</van-col>
          <van-col :span=6
                   class="lineFiveCol">免费时长</van-col>
          <van-col :span=6
                   class="lineFiveCol">重新计费时长</van-col>
          <van-col :span=6
                   class="lineFiveCol">封顶金额</van-col>
        </van-row>
        <van-row class="lineFive">
          <van-col :span=6
                   class="lineFiveCol">{{parkPopBillList.nightBillingPrice}}元</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{parkPopBillList.nightFreeDuration}}分钟</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{parkPopBillList.nightMinBillingDuration}}分钟</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{parkPopBillList.nightCeilingAmount}}元</van-col>
        </van-row>
        <van-button class="parkPopButton"
                    @click=handleClickPopButton(parkPopList)>去这里</van-button>
      </van-row>
    </van-popup>
    <!--点击附近泊位显示弹出窗 -->
    <van-popup v-model="roadPopupShow"
               position="bottom"
               closeable
               :style="{ height: '60%' }">
      <van-row v-model="roadPopList"
               class="parkPop">
        <van-row class="popParkName">
          {{roadPopList.parkName}}
        </van-row>
        <van-row class="lineTwo">
          <van-col :span=12
                   class="lineTwoCol">
            <van-row class="emptyText">空位</van-row>
            <van-row class="emptyNumber">{{roadPopList.emptySpace}}<span class="emptyNumberSpan">个</span></van-row>
          </van-col>
          <van-col :span=12
                   class="lineTwoCol">
            <van-row class="distanceText">距离</van-row>
            <van-row class="distanceNumber">{{roadPopList.distance}} </van-row>
            <!-- <span class="distanceNumberSpan">千米</span> -->
          </van-col>
        </van-row>
        <van-row class="lineThree">
          <span class="lineTreeText">地址:</span><span class="lineTreeContent">{{roadPopList.address}}</span>
        </van-row>
        <van-row class="textBill">
          收费规则
        </van-row>
        <van-row class="lineFour">
          1.白天({{roadPopBillList.dayBeginTime}}-{{roadPopBillList.dayEndTime}})
        </van-row>
        <van-row class="lineFive">
          <van-col :span=6
                   class="lineFiveCol">计费金额</van-col>
          <van-col :span=6
                   class="lineFiveCol">免费时长</van-col>
          <van-col :span=6
                   class="lineFiveCol">重新计费时长</van-col>
          <van-col :span=6
                   class="lineFiveCol">封顶金额</van-col>
        </van-row>
        <van-row class="lineFive">
          <van-col :span=6
                   class="lineFiveCol">{{roadPopBillList.dayBillingPrice}}元</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{roadPopBillList.dayFreeDuration}}分钟</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{roadPopBillList.dayMinBillingDuration}}分钟</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{roadPopBillList.dayCeilingAmount}}元</van-col>
        </van-row>
        <van-row class="lineFour">
          2.夜间({{roadPopBillList.nightBeginTime}}-{{roadPopBillList.nightEndTime}})
        </van-row>
        <van-row class="lineFive">
          <van-col :span=6
                   class="lineFiveCol">计费金额</van-col>
          <van-col :span=6
                   class="lineFiveCol">免费时长</van-col>
          <van-col :span=6
                   class="lineFiveCol">重新计费时长</van-col>
          <van-col :span=6
                   class="lineFiveCol">封顶金额</van-col>
        </van-row>
        <van-row class="lineFive">
          <van-col :span=6
                   class="lineFiveCol">{{roadPopBillList.nightBillingPrice}}元</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{roadPopBillList.nightFreeDuration}}分钟</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{roadPopBillList.nightMinBillingDuration}}分钟</van-col>
          <van-col :span=6
                   class="lineFiveCol">{{roadPopBillList.nightCeilingAmount}}元</van-col>
        </van-row>
        <van-button class="parkPopButton"
                    @click=handleClickPopButton(roadPopList)>去这里</van-button>
      </van-row>
    </van-popup>
    <!-- 底部 -->
    <van-tabbar v-model="active"
                @change="onChange">
      <van-tabbar-item name="homePage"
                       icon="home-o"
                       @click="handleClickHomePage">首页</van-tabbar-item>
      <van-tabbar-item name="park"
                       icon="search">
        停车</van-tabbar-item>
      <van-tabbar-item name="myself"
                       icon="friends-o"
                       @click="handleClickMyself">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script >
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      active: 'park', // 底部显示park
      topParkName: '', // 顶部搜索停车场
      showParkType: 1, // 显示附近停车场 1为附近停车场 2为附近路段泊位
      parkList: [], // 停车场数据
      onroadPark: [], // 停车路段数据
      map: '', // map对象
      mylatitude: 29.718143, // 当前经度
      mylongitude: 106.631187, // 当前纬度
      deslatitude: 29.718143, // 标点经度
      deslongitude: 106.631187, // 标点纬度
      myaddress: '', // 当前地址
      markerList: [], // 停车场标点
      markerListRoad: [], // 停车路段标点
      parkPopupShow: false, // 附近停车场弹窗显示
      parkPopList: {}, // 停车场详情显示
      parkPopBillList: {}, // 停车场详情显示
      roadPopupShow: false, // 附近泊位弹窗显示
      roadPopList: {}, // 停车场详情显示
      roadPopBillList: {} // 停车场详情显示
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.currentPosi()// 获取当前位置
    // this.queryParkDistance()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取当前位置
    currentPosi () {
      const that = this
      const mapObj = new window.AMap.Map('map')
      // 由于ios无法直接通过geolocation获取，进行如下独立逻辑
      if (window.webkit) {
        window.webkit.messageHandlers.saveLocation.postMessage('')
        const coordinate = JSON.parse(this.$store.getters.coordinate)
        that.mylatitude = coordinate.latitude
        that.mylongitude = coordinate.longitude
        that.queryParkDistance()
        return
      }
      mapObj.plugin('AMap.Geolocation', function () {
        const geolocation = new window.AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
          maximumAge: 0, // 定位结果缓存0毫秒，默认：0
          convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: false, // 显示定位按钮，默认：true
          buttonPosition: 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new window.AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        })
        mapObj.addControl(geolocation)
        geolocation.getCurrentPosition()// 获取当前定位
        window.AMap.event.addListener(geolocation, 'complete', onComplete)// 返回定位信息
        // 解析定位结果 获取经度 纬度 本地名称
        function onComplete (data) {
          that.mylatitude = data.position.lat
          that.mylongitude = data.position.lng
          that.myaddress = data.formattedAddress
          that.initMap()
          that.queryParkDistance()
        }
        window.AMap.event.addListener(geolocation, 'error', onError) // 返回定位出错信息
        // 定位出错结果
        function onError (data) {
          console.log(data)
        }
      })
    },
    // 停车场距离最近的前三个查询
    queryParkDistance () {
      const info = {
        longitude: this.mylongitude,
        latitude: this.mylatitude
      }
      this.$carParking.parkNearByNoLimit(info).then(res => {
        res.resultEntity.forEach(item => {
          // 剩余车位不为零的前三个停车场
          if (item.parkTypeCode === 2 && item.emptySpace !== 0) {
            item.distance = Math.round(Number(item.distance) * 1000).toFixed(0)
            item.distance = Number(item.distance) / 1000
            if (parseInt(Number(item.distance)) === 0) {
              item.distance = Number(item.distance) * 1000 + '米'
            } else item.distance = item.distance + '千米'
            this.parkList.push(item)
            this.parkList = this.parkList.slice(0, 3)
          } else if (item.parkTypeCode === 1 && item.emptySpace !== 0) {
            item.distance = Math.round(Number(item.distance) * 1000).toFixed(0)
            item.distance = Number(item.distance) / 1000
            if (parseInt(Number(item.distance)) === 0) {
              item.distance = Number(item.distance) * 1000 + '米'
            } else item.distance = item.distance + '千米'
            this.onroadPark.push(item)
            this.onroadPark = this.onroadPark.slice(0, 3)
          }
        })
        this.initMap()
      })
    },
    // 顶部搜索框
    searchParkName (val) {
      this.markerList = []
      this.markerListRoad = []
      if (val !== null && val !== undefined) {
        this.onroadPark = []
        this.parkList = []
        const info = {
          longitude: this.mylongitude,
          latitude: this.mylatitude,
          parkName: val
        }
        this.$carParking.parkNearByNoLimit(info).then(res => {
          res.resultEntity.forEach(item => {
            if (item.parkTypeCode === 2) {
              item.distance = Math.round(Number(item.distance) * 1000).toFixed(0)
              item.distance = Number(item.distance) / 1000
              if (parseInt(Number(item.distance)) === 0) {
                item.distance = Number(item.distance) * 1000 + '米'
              } else item.distance = item.distance + '千米'
              this.parkList.push(item)
              this.parkList = this.parkList.slice(0, 3)
            } else if (item.parkTypeCode === 1) {
              item.distance = Math.round(Number(item.distance) * 1000).toFixed(0)
              item.distance = Number(item.distance) / 1000
              if (parseInt(Number(item.distance)) === 0) {
                item.distance = Number(item.distance) * 1000 + '米'
              } else item.distance = item.distance + '千米'
              this.onroadPark.push(item)
              this.onroadPark = this.onroadPark.slice(0, 3)
            }
          })
          this.initMap()
        })
      } else {
        this.queryParkDistance()
      }
    },
    // 停车场导航按钮
    handleClickNavigate (item) {
      if (window.android) {
        // 安卓打开第三方地图
        window.android.openMap(this.mylatitude, this.mylongitude, this.myaddress, item.latitude, item.longitude, item.address)
      } else if (window.webkit) {
        let info = {
          mylatitude: this.mylatitude,
          mylongitude: this.mylongitude,
          myaddress: this.myaddress,
          latitude: item.latitude,
          longitude: item.longitude,
          address: item.address
        }
        info = JSON.stringify(info)
        // ios打开第三方地图
        window.webkit.messageHandlers.openMap.postMessage(info)
      }
    },
    // 初始化地图
    initMap () {
      this.map = new window.AMap.Map('map', {
        mapStyle: 'amap://styles/macaron', // 设置地图的显示样式
        resizeEnable: true,
        zoom: 16, // 缩放级别
        center: [Number(this.mylongitude), Number(this.mylatitude)] // 中心点坐标
      })
      // 给当前位置加标点
      const marker = new window.AMap.Marker({
        icon: 'https://vdata.amap.com/icons/b18/1/2.png',
        position: new window.AMap.LngLat(this.mylongitude, this.mylatitude)
      })
      this.map.add(marker)// 自身定位标点
      // 添加停车场三个标点
      // const makerPark = new window.AMap.Marker({
      //   position: new window.AMap.LngLat(106.617165, 29.728143)
      // })
      // this.markerList = [makerPark]
      this.parkList.forEach(item => {
        const makerPark = new window.AMap.Marker({
          icon: require('@/assets/carParking/h5Parking.png'),
          position: new window.AMap.LngLat(item.longitude, item.latitude),
          item: item,
          parkName: item.parkName,
          parkcode: 0,
          emptySpace: item.emptySpace,
          longitude: item.longitude,
          latitude: item.latitude
        })
        makerPark.on('click', this.markerScenicClick)
        this.markerList.push(makerPark)
      })
      this.map.add(this.markerList)
      // 添加停车路段三个标点
      this.onroadPark.forEach(item => {
        const makerOnRoadPark = new window.AMap.Marker({
          icon: require('@/assets/carParking/h5Parking.png'),
          position: new window.AMap.LngLat(item.longitude, item.latitude),
          item: item,
          parkName: item.parkName,
          parkcode: 1,
          emptySpace: item.emptySpace,
          longitude: item.longitude,
          latitude: item.latitude
        })
        makerOnRoadPark.on('click', this.markerScenicClick)
        this.markerListRoad.push(makerOnRoadPark)
      })
    },
    // 高德信息窗体
    markerScenicClick (ev) {
      console.log('点击点标记', ev)
      // 信息窗体的内容
      // const content = [
      //   '停车场名称:' + ev.target.Ce.parkName + ' <br>' + '剩余车位:' + ev.target.Ce.emptySpace
      // ]
      // 创建 infoWindow 实例
      // const infoWindow = new window.AMap.InfoWindow({
      //   content: content.join('<br>')
      // })
      // 打开信息窗体
      // infoWindow.open(this.map, [Number(ev.target.Ce.longitude), Number(ev.target.Ce.latitude)])

      if (ev.target.Ce.parkcode === 0) {
        // ev.target.Ce.icon = require('@/assets/carParking/parkSelect.png')
        this.handleClickParkItemDetails(ev.target.Ce.item)
      } else if (ev.target.Ce.parkcode === 1) {
        console.log(ev.target.Ce.icon)
        this.handleClickParkRoadItemDetails(ev.target.Ce.item)
      }
    },
    // 点击停车场每一项
    handleClickParkItem (item) {
      // 移动高德地图坐标点
      const position = [Number(item.longitude), Number(item.latitude)]
      this.map.panTo(position)
    },
    // 停车场详情弹窗显示
    handleClickParkItemDetails (item) {
      this.parkPopList = item
      const info = {
        billingRuleDefId: item.billingRuleDefId
      }
      this.$carParking.queryBillingRule(info).then(res => {
        res.resultEntity.billingRuleDetailList.forEach(item => {
          item.dayBeginTime = Number(item.dayBeginTime) / 60 + ':00'
          item.dayEndTime = Number(item.dayEndTime) / 60 + ':00'
          item.nightBeginTime = Number(item.nightBeginTime) / 60 + ':00'
          item.nightEndTime = Number(Number(item.nightEndTime) / 60 - 24) + ':00'
          item.dayBillingPrice = Number(Number(item.dayBillingPrice) / 100).toFixed(2)
          item.nightBillingPrice = Number(Number(item.nightBillingPrice) / 100).toFixed(2)
          item.nightCeilingAmount = Number(Number(item.nightCeilingAmount) / 100).toFixed(2)
        })
        this.parkPopBillList = res.resultEntity.billingRuleDetailList[0]
        this.parkPopupShow = true
      })
    },
    // 点击停车路段每一项
    handleClickParkRoadItem (item) {
      // 移动高德地图坐标点
      const position = [Number(item.longitude), Number(item.latitude)]
      this.map.panTo(position)
    },
    // 停车路段详情弹窗显示
    handleClickParkRoadItemDetails (item) {
      this.roadPopList = item
      const info = {
        billingRuleDefId: item.billingRuleDefId
      }
      this.$carParking.queryBillingRule(info).then(res => {
        res.resultEntity.billingRuleDetailList.forEach(item => {
          item.dayBeginTime = Number(item.dayBeginTime) / 60 + ':00'
          item.dayEndTime = Number(item.dayEndTime) / 60 + ':00'
          item.nightBeginTime = Number(item.nightBeginTime) / 60 + ':00'
          item.nightEndTime = Number(Number(item.nightEndTime) / 60 - 24) + ':00'
          item.dayBillingPrice = Number(Number(item.dayBillingPrice) / 100).toFixed(2)
          item.nightBillingPrice = Number(Number(item.nightBillingPrice) / 100).toFixed(2)
          item.nightCeilingAmount = Number(Number(item.nightCeilingAmount) / 100).toFixed(2)
        })
        this.roadPopBillList = res.resultEntity.billingRuleDetailList[0]
        this.roadPopupShow = true
      })
    },
    // 弹出窗内的导航
    handleClickPopButton (item) {
      console.log(item)
      if (window.android) {
        // 安卓打开第三方地图
        window.android.openMap(this.mylatitude, this.mylongitude, this.myaddress, item.latitude, item.longitude, item.address)
      } else if (window.webkit) {
        let info = {
          mylatitude: this.mylatitude,
          mylongitude: this.mylongitude,
          myaddress: this.myaddress,
          latitude: item.latitude,
          longitude: item.longitude,
          address: item.address
        }
        info = JSON.stringify(info)
        // ios打开第三方地图
        window.webkit.messageHandlers.openMap.postMessage(info)
      }
    },
    // 点击附近停车场、附近停车路段
    handleClickChange (type) {
      this.showParkType = type
      if (this.showParkType === 1) {
        // add添加标点，remove去除标点
        this.map.add(this.markerList)
        this.map.remove(this.markerListRoad)
      } else if (this.showParkType === 2) {
        this.map.add(this.markerListRoad)
        this.map.remove(this.markerList)
      }
    },
    // 切换事件
    onChange (index) {
      console.log(index)
    },
    // 点击底部首页
    handleClickHomePage () {
      this.$router.replace('/homePage')
    },
    // 点击底部我的
    handleClickMyself () {
      this.$router.replace('/myself')
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .map-container {
    width: 100%;
    height: 40%;
    z-index: 0;
  }
  .search {
    position: absolute;
    top: 15px;
    left: 20%;
    z-index: 1000;
  }
  .middle {
    position: relative;
    background: white;
    width: 100%;
    box-sizing: border-box;
    padding: 2px 0px 0px 0px;
    height: calc(60% - 50px);
    // bottom: -10px;
    // height: 100%;
    .middle-info {
      height: calc(100% - 38px);
      overflow: scroll;
    }
    .middle-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 5px;
      margin-bottom: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .tab-item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .select-tab {
        width: max-content;
        box-sizing: border-box;
        padding-bottom: 7px;
      }
      .select-tab-active {
        width: max-content;
        box-sizing: border-box;
        padding-bottom: 7px;
        position: relative;
        color: #409eff;
        &::after {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #409eff;
        }
      }
    }

    .icon-title {
      font-size: 13px;
      color: black;
      border-bottom: 0.5px #999999 solid;
      padding: 5px 12px;
      align-items: center;
      display: flex;

      .icon {
        margin-right: 5px;
        line-height: 20px;
        width: 23px;
        height: 23px;
      }
      .title-info {
        color: #409eff;
        margin: 0 3px;
      }
    }
    .list-box {
      // background: red;
      height: 100%;
    }
    .main-info {
      display: flex;
      align-items: center;
      .main-info-list {
        margin-right: 16px;
        border-right: 0.3px #999999 solid;
      }
      .right-info {
        // padding: 5px 0;
        align-items: center;
      }
    }
    .main-list {
      // margin: 2px 0;
      color: #606266;
      letter-spacing: 1px;
      .list-info {
        color: #409eff;
      }
    }

    /deep/.temp-icon {
      width: 28px;
      height: 28px;
    }
    .temp-daohan {
      font-size: 14px;
    }
    .middle-title {
      font-size: 15px;
      color: #999999;
    }
  }
  .bottom {
    position: absolute;
    bottom: 50px;
    display: flex;
    justify-content: space-around;
    .bottom-view {
      padding: 2px 50px;
      text-align: center !important;
      font-size: 18px;
      color: #999999;
    }
  }
  // 底部
  .down {
    position: fixed;
    width: 100%;
    height: 40px;
  }
  .parkPop {
    width: 100%;
    height: 100%;
    .popParkName {
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-family: PingFang-SC-Medium;
      font-size: 17px;
      color: #333333;
    }
    .lineTwo {
      width: 100%;
      height: 65px;
      .lineTwoCol {
        height: 65px;
        .emptyText {
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #888888;
          font-family: PingFang-SC-Regular;
          font-size: 15px;
        }
        .emptyNumber {
          height: 35px;
          line-height: 35px;
          text-align: center;
          color: #19a9fc;
          font-family: PingFang-SC-Bold;
          font-size: 21px;
          .emptyNumberSpan {
            color: #888888;
            font-size: 18px;
          }
        }
        .distanceText {
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #888888;
          font-family: PingFang-SC-Regular;
          font-size: 15px;
        }
        .distanceNumber {
          height: 35px;
          line-height: 35px;
          text-align: center;
          color: #19a9fc;
          font-family: PingFang-SC-Bold;
          font-size: 21px;
          .distanceNumberSpan {
            color: #888888;
            font-size: 18px;
          }
        }
      }
    }
    .lineThree {
      width: 100%;
      height: 37px;
      line-height: 37px;
      padding-left: 3.33%;
      .lineTreeText {
        font-family: PingFang-SC-Medium;
        font-size: 15px;
        color: #333333;
      }
      .lineTreeContent {
        margin-left: 3.33%;
        font-family: PingFang-SC-Regular;
        font-size: 15px;
        color: #666666;
      }
    }
    .textBill {
      width: 100%;
      height: 25px;
      line-height: 25px;
      padding-left: 3.33%;
      font-family: PingFang-SC-Medium;
      font-size: 15px;
    }
    .lineFour {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: PingFang-SC-Regular;
      color: #666666;
      font-size: 15px;
      padding-left: 3.33%;
    }
    .lineFive {
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: PingFang-SC-Regular;
      font-size: 13px;
      color: #666666;
      .lineFiveCol {
        text-align: center;
      }
    }
    .parkPopButton {
      width: 93.6%;
      height: 40px;
      margin-left: 3.2%;
      background: #19a9fc;
      color: white;
    }
  }
}
.van-tabs__nav--line {
  position: relative;
}
/deep/.van-tabs__content {
  height: 300px;
  position: relative;
  padding: 15px;
  top: -335px;
}
/deep/.van-tabs__line {
  background-color: #409eff;
}
/deep/.van-search {
  background: none;
  .van-search .van-cell {
    background-color: white;
    border-radius: 20px;
  }
}
</style>
